import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import IssuesHeader from "../../components/issues-header"
import IssueCards from "../../components/issue-cards"

import brookeKaufman from '../../images/endorsements/brooke-kaufman.webp'
import cindyEngelhardt from '../../images/endorsements/cindy-engelhardt.webp'
import climateActionRhodeIsland from '../../images/endorsements/climate-action-rhode-island-logo.webp'
import dianaAfonso from '../../images/endorsements/diana-afonso.webp'
import henryPreston from '../../images/endorsements/henry-preston.webp'
import housingOpportunitiesForPeopleEverywhereLogo from '../../images/endorsements/housing-opportunities-for-people-everywhere-logo.webp'
import jacobFrey from '../../images/endorsements/jacob-frey.webp'
import janetIsserlis from '../../images/endorsements/janet-isserlis.webp'
import jeffNoble from '../../images/endorsements/jeff-noble.webp'
import jessicaGiorgi from '../../images/endorsements/jessica-giorgi.webp'
import johnnyBrito from '../../images/endorsements/johnny-brito.webp'
import kendraAnderson from '../../images/endorsements/kendra-anderson.webp'
import nancySafran from '../../images/endorsements/nancy-safran.webp'
import ninaMarcov from '../../images/endorsements/nina-marcov.webp'
import peterHurley from '../../images/endorsements/peter-hurley.webp'
import providenceFireFightersLogo from '../../images/endorsements/providence-fire-fighters-logo.webp'
import providenceSunriseMovementLogo from '../../images/endorsements/providence-sunrise-movement-logo.webp'
import providenceTeachersUnionLogo from '../../images/endorsements/providence-teachers-union-logo.webp'
import ruthBreindel from '../../images/endorsements/ruth-breindel.webp'
import sandyOliveria from '../../images/endorsements/sandy-oliveria.webp'
import steveFletcher from '../../images/endorsements/steve-fletcher.webp'
import sumbulSiddiqui from '../../images/endorsements/sumbul-siddiqui.webp'
import substanceUsePolicyEducationRecoveryPac from '../../images/endorsements/substance-use-policy-education-recovery-pac-logo.webp'
import tiaraMack from '../../images/endorsements/tiara-mack.webp'
import youngDemocratsRhodeIslandLogo from '../../images/endorsements/young-democrats-rhode-island-logo.webp'
import yvonneSmart from '../../images/endorsements/yvonne-smart.webp'

const communityEndorsements = [
  {
      "name": "Patrick Wright",
      "title": "Attleboro, MA"
  },
  {
      "name": "Annie Funnell",
      "title": "Barrington, RI"
  },
  {
      "name": "Kyle Bennett",
      "title": "Cranston, RI"
  },
  {
      "name": "Lisa Bouchard",
      "title": "Cranston, RI"
  },
  {
      "name": "Robert Tevyaw",
      "title": "Cranston, RI"
  },
  {
      "name": "William R Jackson",
      "title": "Cumberland, RI"
  },
  {
      "name": "Erika Munoz",
      "title": "Cumberland, RI"
  },
  {
      "name": "Glenn Richard",
      "title": "East Providence, RI"
  },
  {
      "name": "John Heelan",
      "title": "Jamestown, RI"
  },
  {
      "name": "Elizabeth Rounds",
      "title": "Jamestown, RI"
  },
  {
      "name": "Chris Benzak",
      "title": "Newport, RI"
  },
  {
      "name": "Kenneth Sabbagh",
      "title": "North Attleboro, MA"
  },
  {
      "name": "Anne Coyle",
      "title": "Pawtucket, RI"
  },
  {
      "name": "Oscar Dolo",
      "title": "Pawtucket, RI"
  },
  {
      "name": "Eliot Porter",
      "title": "Pawtucket, RI"
  },
  {
      "name": "Jeff Adams",
      "title": "Portsmouth, RI"
  },
  {
      "name": "Richard Pinkham",
      "title": "Portsmouth, RI"
  },
  {
      "name": "Virangini Afzal",
      "title": "Providence, RI"
  },
  {
      "name": "Mark Allio",
      "title": "Providence, RI"
  },
  {
      "name": "Lauren Allister",
      "title": "Providence, RI"
  },
  {
      "name": "Shawn Andrews",
      "title": "Providence, RI"
  },
  {
      "name": "Tom Archie",
      "title": "Providence, RI"
  },
  {
      "name": "Debra Balken",
      "title": "Providence, RI"
  },
  {
      "name": "Maria Best",
      "title": "Providence, RI"
  },
  {
      "name": "Judy Blackadar",
      "title": "Providence, RI"
  },
  {
      "name": "Aaron Brug",
      "title": "Providence, RI"
  },
  {
      "name": "Susan Chaffee-Standish",
      "title": "Providence, RI"
  },
  {
      "name": "Laura Colella",
      "title": "Providence, RI"
  },
  {
      "name": "Kerri Connolly",
      "title": "Providence, RI"
  },
  {
      "name": "Barry Lee Dejasu",
      "title": "Providence, RI"
  },
  {
      "name": "Chris Delgrande",
      "title": "Providence, RI"
  },
  {
      "name": "Doreen Dennis",
      "title": "Providence, RI"
  },
  {
      "name": "Karen Deutsch",
      "title": "Providence, RI"
  },
  {
      "name": "Kristina Duarte",
      "title": "Providence, RI"
  },
  {
      "name": "Morgyn Ellis",
      "title": "Providence, RI"
  },
  {
      "name": "Grace Farmer",
      "title": "Providence, RI"
  },
  {
      "name": "Charles Feldman",
      "title": "Providence, RI"
  },
  {
      "name": "Carole Finger",
      "title": "Providence, RI"
  },
  {
      "name": "Matt Finkelstein",
      "title": "Providence, RI"
  },
  {
      "name": "Faith Fogle",
      "title": "Providence, RI"
  },
  {
      "name": "Theresa Fox",
      "title": "Providence, RI"
  },
  {
      "name": "Fred J. Franklin",
      "title": "Providence, RI"
  },
  {
      "name": "James & Margaret Gardner",
      "title": "Providence, RI"
  },
  {
      "name": "Bruce Goldstein",
      "title": "Providence, RI"
  },
  {
      "name": "Marsha Gomes",
      "title": "Providence, RI"
  },
  {
      "name": "Benjamin Haas",
      "title": "Providence, RI"
  },
  {
      "name": "Naria Halliwell",
      "title": "Providence, RI"
  },
  {
      "name": "Catherine Helgerson",
      "title": "Providence, RI"
  },
  {
      "name": "Timothy Herbert",
      "title": "Providence, RI"
  },
  {
      "name": "Wayne Holland",
      "title": "Providence, RI"
  },
  {
      "name": "Jack Horkings",
      "title": "Providence, RI"
  },
  {
      "name": "Roberta Houllahan",
      "title": "Providence, RI"
  },
  {
      "name": "Ellen House",
      "title": "Providence, RI"
  },
  {
      "name": "Douglas Johnson",
      "title": "Providence, RI"
  },
  {
      "name": "Michael Just",
      "title": "Providence, RI"
  },
  {
      "name": "Kate Colby & Rusty Kinnicutt",
      "title": "Providence, RI"
  },
  {
      "name": "Ronald Laffey",
      "title": "Providence, RI"
  },
  {
      "name": "Catherine Lebovitz",
      "title": "Providence, RI"
  },
  {
      "name": "Evelyn Lincoln",
      "title": "Providence, RI"
  },
  {
      "name": "Evelyn Lincoln",
      "title": "Providence, RI"
  },
  {
      "name": "Karen Longeteig",
      "title": "Providence, RI"
  },
  {
      "name": "Deirdre Lovecky",
      "title": "Providence, RI"
  },
  {
      "name": "Karen Lynch",
      "title": "Providence, RI"
  },
  {
      "name": "Victor Martelle",
      "title": "Providence, RI"
  },
  {
      "name": "Annu Matthew",
      "title": "Providence, RI"
  },
  {
      "name": "Megan McKain",
      "title": "Providence, RI"
  },
  {
      "name": "Helene Miller",
      "title": "Providence, RI"
  },
  {
      "name": "Becky Minard",
      "title": "Providence, RI"
  },
  {
      "name": "Glenn Modica",
      "title": "Providence, RI"
  },
  {
      "name": "Emily Moini",
      "title": "Providence, RI"
  },
  {
      "name": "Nancy Moloney",
      "title": "Providence, RI"
  },
  {
      "name": "Sarah Morenon",
      "title": "Providence, RI"
  },
  {
      "name": "Ilyn Murphy",
      "title": "Providence, RI"
  },
  {
      "name": "Carl Neitzel",
      "title": "Providence, RI"
  },
  {
      "name": "Claire Newell",
      "title": "Providence, RI"
  },
  {
      "name": "Jonathan Niles",
      "title": "Providence, RI"
  },
  {
      "name": "Paul O'Donnell",
      "title": "Providence, RI"
  },
  {
      "name": "Russ Olwell",
      "title": "Providence, RI"
  },
  {
      "name": "Mary Pacheco",
      "title": "Providence, RI"
  },
  {
      "name": "Kurt Raaflaub",
      "title": "Providence, RI"
  },
  {
      "name": "Francine Robbins",
      "title": "Providence, RI"
  },
  {
      "name": "Paula Romasco",
      "title": "Providence, RI"
  },
  {
      "name": "Kathleen Rourke",
      "title": "Providence, RI"
  },
  {
      "name": "Jack Rusley",
      "title": "Providence, RI"
  },
  {
      "name": "Ronna Sanchez",
      "title": "Providence, RI"
  },
  {
      "name": "Bob Schmidt",
      "title": "Providence, RI"
  },
  {
      "name": "Ida Schmulowitz",
      "title": "Providence, RI"
  },
  {
      "name": "Stephanie Silva",
      "title": "Providence, RI"
  },
  {
      "name": "Margaret Smith",
      "title": "Providence, RI"
  },
  {
      "name": "Sylvia Ann Soares",
      "title": "Providence, RI"
  },
  {
      "name": "Seguin Spear",
      "title": "Providence, RI"
  },
  {
      "name": "Sarah Stanbury",
      "title": "Providence, RI"
  },
  {
      "name": "Diane Straker",
      "title": "Providence, RI"
  },
  {
      "name": "Dexter Strong",
      "title": "Providence, RI"
  },
  {
      "name": "Kathleen Thurston-Lighty",
      "title": "Providence, RI"
  },
  {
      "name": "Lynne Tucker",
      "title": "Providence, RI"
  },
  {
      "name": "Ashley Webb",
      "title": "Providence, RI"
  },
  {
      "name": "Jeff Wickham",
      "title": "Providence, RI"
  },
  {
      "name": "Loren Williams",
      "title": "Providence, RI"
  },
  {
      "name": "Loren Williams",
      "title": "Providence, RI"
  },
  {
      "name": "David Wilson",
      "title": "Providence, RI"
  },
  {
      "name": "Joanna Wood",
      "title": "Providence, RI"
  },
  {
      "name": "Gary Van Zante",
      "title": "Providence, RI"
  },
  {
      "name": "Brooke Adams",
      "title": "Providence, RI"
  },
  {
      "name": "Kevin Bostrom",
      "title": "Providence, RI"
  },
  {
      "name": "Maggie Chung",
      "title": "Providence, RI"
  },
  {
      "name": "Sully Difrancesco",
      "title": "Providence, RI"
  },
  {
      "name": "Barbara Garabedian",
      "title": "Providence, RI"
  },
  {
      "name": "Lynn Holstein",
      "title": "Providence, RI"
  },
  {
      "name": "Charles Lonaeus",
      "title": "Providence, RI"
  },
  {
      "name": "Jeffrey Markert",
      "title": "Providence, RI"
  },
  {
      "name": "David Mckinney",
      "title": "Providence, RI"
  },
  {
      "name": "Andrew Mumford",
      "title": "Providence, RI"
  },
  {
      "name": "Arden Reynolds",
      "title": "Providence, RI"
  },
  {
      "name": "Mina Sarmas",
      "title": "Providence, RI"
  },
  {
      "name": "Julia Schoenewald",
      "title": "Providence, RI"
  },
  {
      "name": "Alan Sondheim",
      "title": "Providence, RI"
  },
  {
      "name": "Katherine Touafek",
      "title": "Providence, RI"
  },
  {
      "name": "Alexander Vesprey",
      "title": "Providence, RI"
  },
  {
      "name": "Patricia Leigh Waldron",
      "title": "Providence, RI"
  },
  {
      "name": "Heejae Yang",
      "title": "Providence, RI"
  },
  {
      "name": "Dylan Conly",
      "title": "Rumford, RI"
  },
  {
      "name": "Ellen Walsh",
      "title": "South Dartmouth, MA"
  },
  {
      "name": "Kevin Munro",
      "title": "Swansea, MA"
  },
  {
      "name": "Lynn Johnson",
      "title": "Warwick, RI"
  },
  {
      "name": "Michelle Grove",
      "title": "Wrentham, MA"
  },
]

const generalEndorsements = [
  {
    "image": `${sumbulSiddiqui}`,
    "quote": "",
    "name": "Sumbul Siddiqui",
    "title": "Mayor of Cambridge, MA"
  },
]

const otherEndorsements = [
  {
    "image": `${dianaAfonso}`,
    "quote": "Thank you for your continued strong effort to keep the community a great place to live. My family and I truly appreciate all your hard work!",
    "name": "Diana Afonso",
    "title": "Providence, RI"
  },
  {
    "image": `${ruthBreindel}`,
    "quote": "John Goncalves cares about Providence.  For years, he has been organizing groups to talk about taxes and to bring the various neighborhood associations together into an effective body.  He has been working to help the seniors in his district by collecting fabric masks and getting them to congregate settings; I know this, because I have made masks for him.  In every way, he is the epitome of what the city of Providence needs.",
    "name": "Ruth Breindel",
    "title": "Providence, RI"
  },
  {
    "image": `${cindyEngelhardt}`,
    "quote": "He's a progressive with fresh ideas.",
    "name": "Cindy Engelhardt",
    "title": "Providence, RI"
  },
  {
    "image": `${peterHurley}`,
    "quote": "Hard working!",
    "name": "Peter Hurley",
    "title": "Providence, RI"
  },
  {
    "image": `${janetIsserlis}`,
    "quote": "He has integrity, he listens. He does his homework, he cares about the community and its wellbeing.",
    "name": "Janet Isserlis",
    "title": "Providence, RI"
  },
  {
    "image": `${brookeKaufman}`,
    "quote": "Honest, conscientious, smart and good-hearted.",
    "name": "Brooke Kaufman",
    "title": "Providence, RI"
  },
  {
    "image": `${jeffNoble}`,
    "quote": "Great person, hard-worker, has the common person's interests at heart.",
    "name": "Jeff Noble",
    "title": "Providence, RI"
  },
  {
    "image": `${henryPreston}`,
    "quote": "Cares deeply about our city, respects and protects the historic resources of our city and is super responsive to the residents of his district.",
    "name": "Henry Preston",
    "title": "Providence, RI"
  },
  {
    "image": `${nancySafran}`,
    "quote": "He is a dedicated and tireless advocate for our neighborhood , community and the city.",
    "name": "Nancy Safran",
    "title": "Providence, RI"
  },
  {
    "image": `${jessicaGiorgi}`,
    "quote": "His communication and work while he has served as a council member has been nothing short of wonderful.",
    "name": "Jessica Giorgi",
    "title": "Providence, RI"
  },
  {
    "image": `${yvonneSmart}`,
    "quote": "I have known John since he was a child coming daily to the Fox Point Library. He is an intelligent, caring man whose innate curiosity has always led him to go beyond the easy solutions, the fast answer. He will serve the people well because I know he works hard to analyze each situation and not accept the quick fix.",
    "name": "Yvonne Smart",
    "title": "Providence, RI"
  },
  {
    "image": `${jacobFrey}`,
    "quote": "John Goncalves has the experience and intellect to be a real leader who will champion a forward-thinking vision. As the Mayor of Minneapolis, I've had the great honor of working with John and I'm 100% confident in his ability to drive meaningful change for the benefit of the community.",
    "name": "Jacob Frey",
    "title": "Mayor of Minneapolis, MN"
  },
  {
    "image": `${steveFletcher}`,
    "quote": "John Goncalves served as my Strategy Director during his time living in the Twin Cities. There is no question that he deeply understands policy and has the legislative wherewithal to serve his constituents and effectively get the job done as a member of the Providence City Council.",
    "name": "Steve Fletcher",
    "title": "City Councilman, Ward 3, Minneapolis, MN"
  },
  {
    "image": `${sandyOliveria}`,
    "quote": "John Goncalves has been a part of my life since he was 5 years old. I have always found him to be honest, dedicated, and hardworking. He truly cares about the community he grew up in. I can't think of a better person to help Ward 1 become a stronger and a more vibrant community.",
    "name": "Sandy Oliveria",
    "title": "Retired Librarian, Fox Point Public Librarian"
  },
  {
    "image": `${johnnyBrito}`,
    "quote": "John Goncalves is a homegrown product of the community. His ties to and his investment in the people of Fox Point and the East Side are undeniable. As a lifelong resident of Fox Point, I wholeheartedly support his candidacy.",
    "name": "Johnny Britto",
    "title": "Former Athletic Director of Boys & Girls Club of Providence, Fox Point Resident"
  },
  {
    "image": `${ninaMarcov}`,
    "quote": "I want to make a plug for Ward 1 City Council candidate John Goncalves.  John -- who was born and raised in Fox Point, attended Brown as an undergraduate and for graduate school, and is now a Wheeler School faculty member -- has proven himself to be extremely effective in a very short time; he organized the Providence Coalition of Neighborhood Associations; helped turn out a big crowd to oppose the two-tiered residential tax proposal last spring; and is in favor of regulating student housing developers (unlike some of the other candidates, who represent special interests). For John, residents and the community come first.",
    "name": "Nina Marcov",
    "title": "Member of College Hill Neighborhood Association"
  },
  {
    "image": `${kendraAnderson}`,
    "quote": "I was brought up in Fox Point and the neighborhood has always been important to me.  I pay attention to what is happening there, and as an environmental activist who is also running for office, I am excited to see that Providence Ward 1 Candidater John Calves is committed to tackling environmental racism and creating a Green New Deal in Rhode Island.  When elected, I know he will work tirelessly for environmental and social justice, and that is why I am proud to endorse him in this race!",
    "name": "Kendra Anderson",
    "title": "Senate District 31 Candidate"
  },
  {
    "image": `${tiaraMack}`,
    "quote": "Whether it's his work as an organizer and founder of the Providence Coalition of Neighborhood Associations, his work on numerous non-profit boards, or his willingness to do research on issues before coming to conclusions -- there are so many reasons to support John.\nThere is one particular reason above all that makes John the right choice for the job.\nJohn is the only candidate to name environmental racism as the main reason Providence has one of the highest asthma rates in the country.  We need leaders in Providence that understand all the root causes that lead to economic, education, and environmental inequity so we can work towards building everyone in our communities up.",
    "name": "Tiara Mack",
    "title": "Providence Senate District 6 Candidate"
  },
]

const EndorsementsPage = ({ location }) => (
  <Layout>
    <Seo location={location} title="Endorsements" />
    <div className="bg-white">
        <div className="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:px-8">
          <div className="mt-1 text-center">
            <p className="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
              Endorsements
            </p>
          </div>
        </div>

        <div className="relative bg-gray-200 px-4 sm:px-6 lg:px-8">  
          <div className="relative max-w-7xl mx-auto pt-10 pb-10">
            <h1 className="font-bold text-6xl">Community Endorsements</h1>
            <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
              {communityEndorsements.map((endorsement) => (
                <div key={endorsement.name} className="card flex flex-col shadow-lg overflow-hidden">
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <div className="block">
                        <p className="text-xl font-semibold text-gray-900">{endorsement.name}</p>
                        <p className="text-sm font-medium text-orange-headline">
                          {endorsement.title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="relative bg-gray-200 pb-10 px-4 sm:px-6 lg:pb-10 lg:px-8">  
          <div className="relative max-w-7xl mx-auto pt-10 pb-10">
            <h1 className="font-bold text-6xl">General Endorsements</h1>
            <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
              {generalEndorsements.map((endorsement) => (
                <div key={endorsement.name} className="card flex flex-col shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="w-full object-cover" src={endorsement.image} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <div className="block">
                        <p className="text-xl font-semibold text-gray-900">{endorsement.name}</p>
                        <p className="text-sm font-medium text-orange-headline">
                          {endorsement.title}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="relative bg-gray-100 pb-10 px-4 sm:px-6 lg:pb-10 lg:px-8">  
          <div className="relative max-w-7xl mx-auto pt-8 pb-8">
            <h1 className="font-bold text-3xl">Previously - For Providence City Council</h1>
            <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-4 lg:max-w-none">
              {otherEndorsements.map((endorsement) => (
                <div key={endorsement.name} className="card flex flex-col shadow-lg overflow-hidden">
                  <div className="flex-shrink-0">
                    <img className="w-full object-cover" src={endorsement.image} alt="" />
                  </div>
                  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                    <div className="flex-1">
                      <div className="block">
                        <p className="text-xl font-semibold text-gray-900">{endorsement.name}</p>
                        <p className="text-sm font-medium text-orange-headline">
                          {endorsement.title}
                        </p>
                        <p className="text-lg text-gray-500">{endorsement.quote}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        
        {/*{endorsements.map((endorsement) => (
          <div className="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
            <svg
              className="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
            </svg>

            <div className="relative lg:flex lg:items-center">
              <div className="hidden lg:block lg:flex-shrink-0">
                <img
                  className="h-64 w-64 rounded-full xl:h-80 xl:w-80"
                  src={endorsement.image}
                  alt=""
                />
              </div>

              <div className="relative lg:ml-10">
                <svg
                  className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 144 144"
                  aria-hidden="true"
                >
                  <path
                    strokeWidth={2}
                    d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z"
                  />
                </svg>
                <blockquote className="relative">
                  <div className="text-2xl leading-9 font-medium text-gray-900">
                    <p>
                      {endorsement.quote}
                    </p>
                  </div>
                  <footer className="mt-8">
                    <div className="flex">
                      <div className="flex-shrink-0 lg:hidden">
                        <img
                          className="h-12 w-12 rounded-full"
                          src={endorsement.image}
                          alt=""
                        />
                      </div>
                      <div className="ml-4 lg:ml-0">
                        <div className="text-base font-medium text-gray-900">{endorsement.name}</div>
                        <div className="text-base font-medium text-indigo-600">{endorsement.title}</div>
                      </div>
                    </div>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        ))}*/}
    </div>
  </Layout>
)

export default EndorsementsPage
